import { Component } from "react";
import Form from 'react-bootstrap/Form';

class SearchBoxComponent extends Component {
    constructor (props) {
        super(props)
        this.state = {
            onSubmitPressed: props.onSubmitPressed,
            searchTextEntered: props.searchTextEntered,
            searchEnabled: props.searchEnabled
        }
    }

    render() {
        return(
            <div className="searchBoxComponent">
                <Form.Control placeholder="Search using App Store URL" onChange={this.state.searchTextEntered}>
                </Form.Control>
            </div>
        )
    }
}

export default SearchBoxComponent;