import { Component } from "react";

class ScraperComponent extends Component {
    constructor (props) {
        super(props)
        this.state = {
            appName: props.appName
        }
        this.launchBowser(props.appName)
    }

    async launchBowser(appName) {
    }

    render() {
        return(
            <>
            </>
        )
    }
}

export default ScraperComponent;