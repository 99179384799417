import { Component } from "react";
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import DropDownItem from 'react-bootstrap/DropdownItem'

class DropdownComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            onSelect: props.onSelect,
            store: props.storeSelected
        }
        this.selectedStore = "Store Selection"
        this.onSelect = this.selected.bind(this)
    }

    selected(value) {
        console.log("selected store is " + value);
        this.selectedStore = value;
        this.state.onSelect(value)
    }
    
    render() {
        return(
            <div className="dropdownComponent">
                <Dropdown>
                    <DropdownButton title={ this.selectedStore } onSelect={this.onSelect}>
                        <DropDownItem as="button" eventKey="US">
                            US
                        </DropDownItem>
                        <DropDownItem eventKey="AU">
                            AU
                        </DropDownItem>
                        <DropDownItem eventKey="CA">
                            CA
                        </DropDownItem>
                    </DropdownButton>
                </Dropdown>
            </div>
        )
    }
}

export default DropdownComponent