import { Component } from "react";
import ReviewsTileComponent from "./ReviewTileComponent";
import SearchBoxComponent from "./SearchBoxComponent";
import Button from 'react-bootstrap/Button';
import DropdownComponent from "./DropdownComponent";
import ScraperComponent from "./ScraperComponent";

class ReviewsComponent extends Component {
    constructor (props) {
        super(props)
        this.state = {
            isLoaded: false,
            entry: [],
            searchText: "",
            appId: "",
            searchEnabled: false,
            showError: false,
            storeSelection: "",
            storeSelected: false,
            averageRating: 0,
            totalCount: 0
        }
    }

    lowerRatings(entry) {
        try {
            return parseInt(entry['im:rating'].label, 10) <= 3
        } catch (error) {
            console.log(error + " while parsing ratings");
            return false;
        }
    }

    filterLowerRatings(entry) {
        const lowerRatings = entry.filter(this.lowerRatings)
        if (lowerRatings.length > 0) {
            return lowerRatings;
        } else {
            return [];
        }
    }

    mergeArrays(data, entry) {
        var a = data.concat(entry);
        return a.filter((item, pos) => a.indexOf(item) === pos);
    }

    sortEntriesByDate(entries) {
        entries.sort((a, b) => {
                const date1 = new Date(a.updated.label);
                const date2 = new Date(b.updated.label);
                return (-1 * date1) - (-1 * date2);
            }
        );
        return entries
    }

    getStoreReviews(store, page, appId) {
        console.log("fetching");
        this.entry = []
        console.log("https://itunes.apple.com/" + store + "/rss/customerreviews/page=" + page + "/id=" + appId + "/sortBy=mostRecent/json")
        try {
            fetch("https://itunes.apple.com/" + store + "/rss/customerreviews/page=" + page + "/id=" + appId + "/sortBy=mostRecent/json")
            .then(res => res.json())
            .then(result => {
                    if (result.feed.entry === undefined) {
                        this.setState({
                            showError: true
                        })
                    } else {
                        const entries = this.sortEntriesByDate(result.feed.entry)
                        this.setState({
                            entry: entries
                        })
                        var rating = this.state.averageRating
                        var count = this.state.totalCount
                        entries.forEach(idx => {
                            rating = rating + parseFloat(idx["im:rating"].label)
                            //console.log("rating is " + rating + " over count " + count);
                            count = count + 1;
                        })

                        rating = (rating/count).toFixed(2);

                        this.setState({
                            averageRating: rating,
                            totalCount: count
                        })
                    }
                }
            )
            .catch(function(error){
                console.log(error);
            })
        } catch (err) {
            console.log(err + " is error");
        }
    }

    handleSubmitPressed() {
        if (this.state.searchEnabled) {
            console.log(this.state.searchText + " is search text");
            this.setState({
                averageRating: 0,
                totalCount: 0
            })
            this.getStoreReviews(this.state.storeSelection, 1, this.state.appId)
            //this.getStoreReviews(this.state.storeSelection, 2, this.state.appId)
        } else {
            this.setState({
                showError: true
            })
        }
    }

    searchTextEntered(e) {
        this.setState({
            showError: false
        })
        console.log("search text is " + e.target.value);

        const text = e.target.value;
        var searchEnabled = false;

        if (!this.isValidId(text)) {
            const parts = text.split('id')
            console.log(parts[1]);

            if (parts[1] !== undefined) {
                const id = parts[1].substring(0, 9)
                searchEnabled = this.isValidId(id);

                this.setState({
                    searchText: e.target.value,
                    searchEnabled: searchEnabled,
                    appId: searchEnabled ? id : undefined
                })
            } else {
                this.setState({
                    searchEnabled: searchEnabled,
                    appId: undefined,
                    searchText: undefined
                })
            }
        }
    }

    onSelect(value) {
        console.log(value + " selected");
        this.setState({
            storeSelected: true,
            storeSelection: value,
            showError: false,
            entry: [],
            averageRating: 0,
            totalCount: 0
        })

    }

    isValidId(str) {
        return !isNaN(str) && !isNaN(parseFloat(str)) && str.length === 9
    }

    render() {
        return(
            <div className="primaryContainer">
                <ScraperComponent></ScraperComponent>
                    <DropdownComponent onSelect={this.onSelect.bind(this)}  storeSelected={this.state.storeSelected}></DropdownComponent>
                    {this.state.storeSelected &&
                        <div className="searchComponent">
                            <SearchBoxComponent key={this.state.searchTextEntered} searchTextEntered={this.searchTextEntered.bind(this)}></SearchBoxComponent>
                            {this.state.showError && 
                                <p className="searchBoxErrorText" key={this.state.searchEnabled}>
                                    Hmm. That doesn't look like a valid App Store Id
                                </p>
                            }
                            <Button disabled={!this.state.searchEnabled} className="submitButton" onClick={this.handleSubmitPressed.bind(this)}>Search</Button>
                            {this.state.averageRating !== 0 && 
                                <h3 className="ratingText" key={this.state.averageRating}>The average rating is {this.state.averageRating} over the last {this.state.totalCount} reviews.</h3>
                            }
                            {this.state.entry.map(res =>
                                <ReviewsTileComponent key={res.content.label}
                                    res={res}>
                                {console.log(this.state.entry.length)}
                                </ReviewsTileComponent>
                            )}
                        </div>
                    }
            </div>
        )
    }
}

export default ReviewsComponent