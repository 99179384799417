import { Component } from "react";
import Card from "react-bootstrap/Card";

class ReviewsTileComponent extends Component {
    constructor (props) {
        super(props)
        this.state = {
            res: this.props.res
        };
    }

    getDate(date) {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const d = new Date(date);
        const dom = d.getDate();
        const month = months[d.getMonth()];
        const year = d.getFullYear();
        return dom + "-" + month + "-" + year;
    }

    getStars(rating) {
        const rated = parseInt(rating);
        var stars = "";
        for (var i=0; i<rated; i++) {
            stars += "⭐";
        }
        return stars
    }

    render() {
        return(
            <div className="reviewTile">
            <Card style={{width: '24em', position: 'relative'}}>
                <Card.Body>
                    <Card.Title className="reviewTitle">
                        {this.state.res.author.name.label}
                    </Card.Title>
                    <Card.Subtitle className="reviewSubtitle">
                        {this.getStars(this.state.res["im:rating"].label)}
                    </Card.Subtitle>
                    <Card.Subtitle key={this.state.res.updated.label} className="reviewDate">
                        {this.getDate(this.state.res.updated.label)}
                    </Card.Subtitle>
                    <Card.Text className="ReviewBody">
                        "{this.state.res.content.label}"
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
        )
    }
}

export default ReviewsTileComponent