import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import ReviewsComponent from "./ReviewsComponent";


function App() {
  return (
    <div className="appContainer">
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={ReviewsComponent}>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
